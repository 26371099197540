import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-datetime-formatter',
  template: `{{value |date: 'dd-MM-yyyy HH:mm:ss'}}`
})
export class DateTimeFormatterComponent {
  @Input() value: Date;
}

