import { Injectable } from '@angular/core';
import { NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { duration } from 'moment';

/**
 * Servizio di notifica messaggi utente provvisto di i18n.
 */
@Injectable()
export class AbsToastrService {

  constructor(
    private toastrService: NbToastrService,
    private translateService: TranslateService
  ) { }

  /**
   * Notifica l'utente tramite un toastr di tipo <info>.
   * @param message 
   * @param position opzionale
   */
  info(
    message: string,
    position: string = NbGlobalPhysicalPosition.TOP_RIGHT
  ) {
    this.show(
      `${this.translateService.instant('toastr.infoMessage')}`,
      `${this.translateService.instant(message)}`,
      position, 'info',
      3000
    )
  }

  /**
   * Notifica l'utente tramite un toastr di tipo <success>
   * @param message
   * @param position opzionale
   */
  success(
    message: string,
    position: string = NbGlobalPhysicalPosition.TOP_RIGHT
  ) {
    this.show(
      `${this.translateService.instant('toastr.successMessage')}`,
      `${this.translateService.instant(message)}`,
      position, 'success',
      3000
    )
  }

  /**
   * Notifica l'utente tramite un toastr di tipo <warning>
   * @param message 
   * @param position opzionale
   */
  warning(
    message: string,
    position: string = NbGlobalPhysicalPosition.TOP_RIGHT
  ) {
    this.show(
      `${this.translateService.instant('toastr.warningMessage')}`,
      `${this.translateService.instant(message)}`,
      position, 'warning',
      3000
    )
  }

  /**
   * Notifica l'utente tramite un toastr di tipo <error>
   * @param message 
   * @param position opzionale
   */
  error(
    message: string,
    position: string = NbGlobalPhysicalPosition.TOP_RIGHT
  ) {
    this.show(
      `${this.translateService.instant('toastr.errorMessage')}`,
      `${this.translateService.instant(message)}`,
      position, 'danger',
      0
    )
  }

  /**
   * Notifica l'utente di un errore generico dell'applicazione.
   * @param position opzionale
   */
  serviceError(
    message?: string,
    position: string = NbGlobalPhysicalPosition.TOP_RIGHT
  ) {
    this.show(
      `${this.translateService.instant('toastr.errorMessage')}`,
      message ? `${this.translateService.instant(message)}` : `${this.translateService.instant('message.serviceUnavailable')}`,
      position, 'danger',
      0
    )
  }

  private show(title: string, message: string, position: any, status: any, duration: any) {
    this.toastrService.show(message, title, { position, status , duration}
    )
  }
}
