import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbAuthService, NbRequestPasswordComponent } from '@nebular/auth';
import { AbsToastrService } from '../../shared/abs-toastr.service';
import { RouterParams } from '../../shared/router.params';
import { AuthService } from './../../service/auth/auth.service';


@Component({
  selector: 'ngx-abs-request-password',
  templateUrl: './abs-request-password.component.html',
  styleUrls: ['./abs-request-password.component.scss']
})
export class AbsRequestPasswordComponent extends NbRequestPasswordComponent {

  public form: FormGroup

  constructor(
    service: NbAuthService,
    cd: ChangeDetectorRef,
    router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastrService: AbsToastrService
  ) {
    super(service, null, cd, router);
    this.buildForm();
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      multiField: ['', Validators.required]
    });
  }

  requestPassword() {
    const multiField = this.form.controls['multiField'].value

    this.authService.requestPassword(multiField).subscribe(
      (res: HttpResponse<any>) => {
        this.router.navigate([RouterParams.PATH_RESET_PASSWORD])
      },
      (error: HttpErrorResponse) => {
        switch (error.status) {
          case 404: {
            this.toastrService.error('message.invalidCredentials');
            break;
          }
          default: {
            this.toastrService.serviceError();
            break;
          }
        }
      }
    )
  }
}
