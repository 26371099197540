import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService, NbLogoutComponent } from '@nebular/auth';
import { AuthService } from './../../service/auth/auth.service';

@Component({
  selector: 'ngx-abs-logout',
  template: ''
})
export class AbsLogoutComponent extends NbLogoutComponent {

  constructor(
    service: NbAuthService,
    routes: Router,
    private authService: AuthService
  ) {
    super(service, null, routes);
  }

  ngOnInit() {
    this.authService.logout()
  }
}
