export const regex = {
    /*
    La password deve rispettare le seguenti condizioni:
    ============================================================================
    - almeno un carattere:                                  (?=.*[a-zA-Z])
    - almeno un carattere speciale tra questi               (?=.*[$@$!%*?&_-])
    - contenere almeno 8 caratteri alfanumerici             {8,}
    ============================================================================

    In futuro, sarebbe bene gestire per sicurezza le seguenti condizioni:
    ============================================================================
    - almeno un numero:                                     (?=\\D*\\d)
    - almeno un carattere minuscolo                         (?=[^a-z]*[a-z])
    - almeno un carattere maiuscolo                         (?=[^A-Z]*[A-Z])
    - almeno un carattere speciale tra questi               (?=.*[$@$!%*?&_-])
    - contenere almeno 8 caratteri alfanumerici             {8,}
    ============================================================================
    */
    password: '((?=.*[a-zA-Z])(?=.*[$@$!%*?&_-]).{8,})'
}