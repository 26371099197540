import { HttpClient, HttpHeaders, HttpResponseBase, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { NGXLogger } from 'ngx-logger'
import { Observable, throwError as observableThrowError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { EnvService } from '../shared/env.service'
import { TokenService } from './auth/token.service'

const httpHeaders = new HttpHeaders({
  'Content-Type': 'application/json',
  'Accept': 'application/json',
})

/**
 * Generic response body
 */
export interface ApiResponse<T> {
  data: T
}

/**
 * Service Facade per effettuare richieste client -> server.
 *
 * Aggiunge a tutte le requests effettuate un header Bearer: <access_token>
 * per l'accesso alle API del backend.
 *
 * Deve essere utilizzato una volta autenticato l'utente,
 * per disaccoppiare le requests di autenticazione da quelle di autorizzazione.
 */
@Injectable()
export class ApiService {
  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    private tokenService: TokenService,
    private envService: EnvService
  ) { }

  private baseUrl = this.envService.apiUrl;

  /**
   * Aggiunge ad ogni request il token autorizzativo
   */
  private headersWithToken(): HttpHeaders {
    return httpHeaders.set(
      'Authorization', `Bearer ${this.tokenService.getAccessToken()}`
    )
  }

  private headersWithTokenAndType(): HttpHeaders {
    let headers = new HttpHeaders({
      'Authorization': `Bearer ${this.tokenService.getAccessToken()}`,
      'Content-Type': 'multipart/form-data',

    })
    return headers;
  }

  /**
   * Developers tool: logga l'errore ricevuto in console tra quelli mappati
   * e lo rilancia al servizio chiamante, che può implementare delle logiche
   * personalizzate semplicemente facendo il subscribe di una HttpErrorResponse,
   * wrapper per la HttpResponseBase mappata dall'ApiService.
   *
   * @param error
   */
  private formatErrors(error: any) {
    this.logger.debug(error)
    return observableThrowError(error)
  }

  get(path: string, params): Observable<any> {
    return this.http.get(
      this.baseUrl + path,
      { headers: this.headersWithToken(), params: params })
      .pipe(
        catchError((res: HttpErrorResponse) => this.formatErrors(res.error)),
        map((res: HttpResponseBase) => res)
      )
  }

  getText(path: string, params): Observable<any> {
    return this.http.get(
      this.baseUrl + path,
      { headers: this.headersWithToken(), params: params, responseType: 'text' })
      .pipe(
        catchError((res: HttpErrorResponse) => this.formatErrors(res.error)),
        map((res: any) => res)
      )
  }

  getWithCredentials(path: string, params): Observable<any> {
    return this.http.get(
      this.baseUrl + path,
      {
        headers: this.headersWithToken(),
        params: params,
        withCredentials: true
      }
    ).pipe(
      catchError((res: HttpErrorResponse) => this.formatErrors(res.error)),
      map((res: HttpResponseBase) => res)
    )
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      this.baseUrl + path,
      body,
      {
        headers: this.headersWithToken(),
        withCredentials: true
      }
    ).pipe(
      catchError((res: HttpErrorResponse) => this.formatErrors(res.error)),
      map((res: HttpResponseBase) => res)
    )
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      this.baseUrl + path,
      body,
      { headers: this.headersWithToken() }
    ).pipe(
      catchError((error: any) => this.formatErrors(error)),
      map((res: HttpResponseBase) => res)
    )
  }

  delete(path): Observable<any> {
    return this.http.delete(
      this.baseUrl + path,
      {
        headers: this.headersWithToken(),
        withCredentials: false
      }
    ).pipe(
      catchError((res: HttpErrorResponse) => this.formatErrors(res.error)),
      map((res: HttpResponseBase) => res)
    )
  }

  patch(path: string, body: Object = {}): Observable<any> {
    return this.http.patch(
      this.baseUrl + path,
      body,
      { headers: this.headersWithToken() }
    ).pipe(
      catchError((res: HttpErrorResponse) => this.formatErrors(res.error)),
      map((res: HttpResponseBase) => res)
    )
  }
}
