<h1 id="title" class="title"> {{ 'message.passwordForgot' | translate }} </h1>
<p class="sub-title">{{ 'message.requestPasswordForm' | translate}}</p>

<form (ngSubmit)="requestPassword()" [formGroup]="form" aria-labelledby="title">
    <div class="form-control-group">
        <label class="label"> {{ 'field.requestPassword' | translate }}: </label>
        <input nbInput fullWidth formControlName="multiField" fieldSize="large"
            [status]="form.get('multiField').invalid ? 'danger' : 'success'" autofocus>
    </div>
    <button nbButton fullWidth status="primary" size="large" [class.btn-pulse]="submitted"
        [disabled]="submitted || !form.valid">
        {{ 'Invia' | translate }}
    </button>
</form>

<section class="sign-in-or-up">
    <p><a class="text-link" routerLink="../login"> {{ 'message.backToLogin' | translate}}</a></p>
</section>