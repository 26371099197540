export class RouterParams {

  public static PATH_DEFAULT          = ''
  public static PATH_SLASH            = '/'
  public static PATH_LOGIN            = '/auth/login'
  public static PATH_RESET_PASSWORD   = '/auth/reset-password'
  public static PATH_DASHBOARD        = '/pages/dashboard'
  public static PATH_CAMPAGNE         = '/pages/campagne'
  public static PATH_ARTICOLI         = '/pages/articoli'
  public static PATH_PER_TE           = '/pages/per-te'
  public static PATH_UTENTI           = '/pages/utenti'
  public static PATH_GRUPPI           = '/pages/gruppi'
  public static PATH_AMMINISTRATIVI   = '/pages/amministrativi'
  public static PATH_PER_TE_PUBLIC    = '/pages/per-te-public'
  public static PATH_ARTICOLI_PUBLIC  = '/pages/articoli-public'
  public static PATH_TROFEI           = '/pages/trofei'

  public static PAGE_DASHBOARD        = 'dashboard'
  public static PAGE_CAMPAGNE         = 'campagne'
  public static PAGE_ARTICOLI         = 'articoli'
  public static PAGE_PER_TE           = 'per-te'
  public static PAGE_GRUPPI           = 'gruppi'
  public static PAGE_UTENTI           = 'utenti'
  public static PAGE_AMMINISTRATIVI   = 'amministrativi'
  public static PAGE_PER_TE_PUBLIC    = 'per-te-public'
  public static PAGE_ARTICOLI_PUBLIC  = 'articoli-public'
  public static PAGE_TROFEI           = 'trofei'

}
