<div class="header-container" [style.background-color]="navbar_color">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline" [style.color]="navbar_menu_color"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img src="{{navbar_logo}}" />
    </a>
  </div>
</div>
<div class="header-container">
  <span style="margin-right: 5px;" [style.color]="navbar_menu_color"><small><b>v2.1.1</b></small></span>
  <nb-select status="primary" [(selected)]="curLang" class="select-box" style="margin-left: 5px;">
    <nb-option *ngFor="let language of languages" value="{{ language.code }}" (click)="changeLanguage(language.code)">
      <img width="25" src="./assets/images/flags/{{language.code}}.png"> {{language.label}}</nb-option>
  </nb-select>
  <nb-actions size="small">
    <nb-action class="control-item" style="cursor: pointer;" [style.color]="navbar_menu_color" (click)="downloadPDF()">{{ 'MANUALE' | translate }}</nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" [nbContextMenu]="userMenu">
      <span [style.color]="navbar_menu_color">{{ user.name }}</span>
    </nb-action>
  </nb-actions>
</div>