import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbAuthService, NbLoginComponent } from '@nebular/auth';
import { AbsToastrService } from '../../shared/abs-toastr.service';
import { RouterParams } from '../../shared/router.params';
import { AuthService } from './../../service/auth/auth.service';
import { SessionService } from './../../service/auth/session.service';
import { TokenRequest, TokenResponse } from './../../service/auth/token.service';
import { regex } from './../../shared/regex';

@Component({
  selector: 'ngx-abs-login',
  templateUrl: './abs-login.component.html',
  styleUrls: ['./abs-login.component.scss']
})
export class AbsLoginComponent extends NbLoginComponent {

  public loginForm: FormGroup;

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    service: NbAuthService,
    cd: ChangeDetectorRef,
    routes: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private sessionService: SessionService,
    private toastrService: AbsToastrService
  ) {
    super(service, null, cd, routes);
    this.buildForm();
    (document.querySelector('.nb-theme-default nb-card-header') as HTMLElement).style.display = 'none';

  }

  private buildForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      // password: ['', [Validators.required, Validators.pattern(regex.password)]]
      password: ['', [Validators.required]]
    });
  }

  /**
   * Effettua la login e inizializza la sessione utente
   */
  login() {

    const tokenRequest: TokenRequest = {
      username: this.loginForm.controls['username'].value,
      password: this.loginForm.controls['password'].value,
      grant_type: "password"
    };

    this.authService.login(tokenRequest).subscribe((res: TokenResponse) => {
      this.sessionService.save({
        accessToken: res.access_token,
        userData: {
          username: res.username,
          email: res.email,
          userId: res.user_id,
          companyId: res.company_id,
          role: res.role,
          tokenIssuedAt: res.created_at,
          tokenExpiresIn: res.expires_in
        }
      })
      this.router.navigate([RouterParams.PATH_SLASH]);
    },
      (error: HttpErrorResponse) => {
        switch (error.status) {
          case 401: {
            this.toastrService.error('message.invalidCredentials');
            break;
          }
          default: {
            this.toastrService.serviceError();
            break;
          }
        }
      }
    )
  }
}
