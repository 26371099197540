import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AbsToastrService } from '../../shared/abs-toastr.service';
import { RouterParams } from '../../shared/router.params';
import { AuthService } from './../../service/auth/auth.service';
import { regex } from './../../shared/regex';

@Component({
  selector: 'ngx-abs-reset-password',
  templateUrl: './abs-reset-password.component.html',
  styleUrls: ['./abs-reset-password.component.scss']
})
export class AbsResetPasswordComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toastrService: AbsToastrService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      secret: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern(regex.password)]],
    });
  }

  resetPassword() {
    const username = this.form.controls['username'].value
    const secret = this.form.controls['secret'].value
    const password = this.form.controls['password'].value

    this.authService.resetPassword(username, secret, password).subscribe(
      (res: HttpResponse<any>) => {
        this.router.navigate([RouterParams.PATH_LOGIN])
      },
      (error: HttpErrorResponse) => {
        switch (error.status) {
          case 401: {
            this.toastrService.error('message.invalidCode')
            break;
          }
          case 404: {
            this.toastrService.error('message.invalidCredentials')
            break;
          }
          default: {
            this.toastrService.serviceError()
            break;
          }
        }
      }
    )
  }
}
