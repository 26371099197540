import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ApiResponse } from '../../../service/api.service';
import { SessionService } from '../../../service/auth/session.service';
import { CompanyService } from '../../../service/company.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode style="background-image: url('assets/images/back.jpg');background-size: auto 100%;
    background-position: left top;">
      <nb-layout-header fixed [style.background-color]="navbar_color">
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  // Global variables
  companyId: number
  navbar_menu_color: any
  navbar_color: any

  constructor(
    private companyService: CompanyService,
    private sessionService: SessionService,
  ) {
    this.companyId = this.sessionService.get().userData.companyId;
    this.fetchTemplate()
  }

  fetchTemplate() {
    this.companyService.getTemplate(this.companyId).subscribe(
      (response: ApiResponse<any>) => {
        let data = response
        this.navbar_menu_color = data.data.navbar_menu_color
        this.navbar_color = data.data.navbar_color
      },
      (error: HttpErrorResponse) => { })
  }

}
