import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardService {

  constructor(private api: ApiService) { }

  private baseUrl2 = '/v2/companies/';
  private baseUrl1 = '/v1/companies/';

  getPostsFiltered(companyId: any, params: any) {
    return this.api.post(this.baseUrl2 + companyId + '/posts', params).pipe(
      map((res: any) => {
        if (res) return res;
        else return null;
      }));
  }

  getChartFiltered(companyId: any, params: any) {
    return this.api.post(this.baseUrl2 + companyId + '/chart', params).pipe(
      map((res: any) => {
        if (res) return res;
        else return null;
      }));
  }

  getPostsCover(companyId: any, postId: any, params: any) {
    return this.api.post(this.baseUrl2 + companyId + '/posts/' + postId + '/preview_analytics', params).pipe(
      map((res: any) => {
        if (res) return res;
        else return null;
      }));
  }

  getAverages(companyId: any, params: any) {
    return this.api.post(this.baseUrl2 + companyId + '/analytics', params).pipe(
      map((res: any) => {
        if (res) return res;
        else return null;
      }));
  }

  getPostLeaderboard(companyId: number, campaignId: number, postId: number) {
    return this.api.get(`${this.baseUrl1}${companyId}/campaigns/${campaignId}/posts/${postId}/leaderboard`, null).pipe(
      map((res: any) => {
        if (res) return res;
        else return null;
      }));
  }

  getPostAnalytics(companyId: number, postId: number) {
    return this.api.post(`${this.baseUrl2}${companyId}/posts/${postId}/detail_analytics`, null).pipe(
      map((res: any) => {
        if (res) return res;
        else return null;
      }));
  }

  getPostFeedbackComments(companyId: number, postId: number, params?: any) {
    return this.api.post(`${this.baseUrl2}${companyId}/posts/${postId}/feedback_comments`, params).pipe(
      map((res: any) => {
        if (res) return res;
        else return null;
      }));
  }

}
