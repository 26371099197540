import { Injectable } from '@angular/core'
import { UserDataService } from './user-data.service'

const ACCESS_TOKEN_KEY = 'ADV_access_token'

export interface TokenRequest {
  username: string,
  password: string,
  grant_type: string
}

export interface TokenResponse {
  access_token: string,
  token_type: string,
  expires_in: number,
  scope: string,
  created_at: number,
  user_id: number,
  company_id: number,
  username: string,
  email: string,
  role: number,
  job_position: string,
  is_new: boolean,
  notification_key: string,
  nickname: string,
  ranking: any,
  avatar: number
}

/**
 * Servizio di gestione token autorizzativo in session storage
 */
@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private userDataService: UserDataService
  ) { }

  /**
   * Salva il token autorizzativo in session storage.
   * @param token 
   */
  public saveAccessToken(token: string) {
    sessionStorage.removeItem(ACCESS_TOKEN_KEY);
    sessionStorage.setItem(ACCESS_TOKEN_KEY, token)
  }

  /**
   * Recupera il token autorizzativo in session storage.
   */
  public getAccessToken(): string {
    return sessionStorage.getItem(ACCESS_TOKEN_KEY)
  }

  /**
   * Rimuove il token autorizzativo in session storage.
   */
  public deleteAccessToken() {
    sessionStorage.removeItem(ACCESS_TOKEN_KEY)
  }

  /**
   * Recuperare i dati temporali relaviti al token autorizzativo
   * dal session storage.
   */
  public getTokenExpiration(): Array<number> {
    const userData = this.userDataService.get()
    return [userData.tokenIssuedAt, userData.tokenExpiresIn]
  }
}
