import { Injectable } from '@angular/core';

@Injectable()
export class DataService {

  constructor() { }

  public data: any;

  get(){
      return this.data;
  }

  set(data: any){
      this.data = data;
  }

}
