import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs/Rx'
import { AuthService } from './auth.service'

/**
 * Interceptor per tutte le chiamate client -> server.
 * 
 * Attualmente il server non implementa una politica di refresh_token,
 * pertanto al momento e' sufficiente inviare la richiesta al server, il quale
 * nel caso di token scaduto risponde con status 401 (Unauthorized).
 * 
 * Future politiche di gestione del token dovranno essere implementate qui.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).catch(error => {
            /*
             Se la response e' unauthorized, si effettua un pattern matching
             sui possibili servizi che tornano 401 come response, altrimenti
             viene sempre automaticamente fatto un redirect alla login
             (anche se erroneamente interpretati, dovrebbero essere dei 403)
             */
            if (error.status === 401) {
                const url = request.url
                if (url.endsWith('passwordupdate')) {
                    return Observable.throw(error)
                }
                else {
                    this.authService.logout()
                    return Observable.throw(error)
                }
            }
            /*
             Se la response e' forbidden, c'e' un problema di autorizzazione
             (o di token scaduto?)
             */
            else if (error.status === 403) {
                this.authService.logout()
                return Observable.throw(error)
            }
            // Altrimenti rilancia l'errore
            else {
                return Observable.throw(error)
            }
        })
    }
}
