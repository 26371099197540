import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class ChartService {

  constructor(private api: ApiService) { }

  private baseUrl = '/v1/companies/';

  get(companyId: any) {
    return this.api.get(this.baseUrl + companyId + '/chart', null).pipe(
      map((res: any) => {
        if (res) return res;
        else return null;
      }));
  }

}
