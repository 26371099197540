import { Injectable } from '@angular/core'

const USER_DATA_KEY = "ADV_user_data"

export enum Role {
  ADMINISTRATOR = 1
}

export interface UserData {
  username: string
  email: string,
  userId: number,
  companyId: number,
  role: number,
  tokenIssuedAt: number
  tokenExpiresIn: number
}

/**
 * Servizio di gestione dati utente
 */
@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  constructor() { }

  /**
   * Salva i dati utente nel session storage in formato json
   * @param userData 
   */
  save(userData: UserData) {
    sessionStorage.setItem(USER_DATA_KEY, JSON.stringify(userData))
  }

  /**
   * Recupera i dati utente dal session storage
   */
  get(): UserData {
    return JSON.parse(sessionStorage.getItem(USER_DATA_KEY))
  }

  /**
   * Rimuove i dati utente del session storage
   */
  delete() {
    sessionStorage.removeItem(USER_DATA_KEY)
  }

}
