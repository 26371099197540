import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouterParams } from '../../shared/router.params';
import { TokenService } from './token.service';
import { UserData, UserDataService } from './user-data.service';


const USER_DASH_RANGE_PREF_START = 'dashRangeStartPref'
const USER_DASH_RANGE_PREF_END = 'dashRangeEndPref'

export interface Session {
  accessToken: string
  userData: UserData
}

/**
 * Servizio di gestione sessione utente (per ora CLIENT-ONLY)
 */
@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    private tokenService: TokenService,
    private userDataService: UserDataService,
    private router: Router,
  ) { }

  /**
   * Inizializza la sessione utente, effettuando le seguenti azioni:
   * - Salva il token autorizzativo nel session storage
   * - Salva i dati utente nel session storage
   * @param session
   */
  save(session: Session) {

    this.tokenService.saveAccessToken(session.accessToken)

    this.userDataService.save({
      // dati utente
      username: session.userData.username,
      email: session.userData.email,
      role: session.userData.role,

      // dati compagnia
      userId: session.userData.userId,
      companyId: session.userData.companyId,

      // dati token
      tokenIssuedAt: session.userData.tokenIssuedAt,
      tokenExpiresIn: session.userData.tokenExpiresIn
    })
  }

  /**
   * Ritorna la sessione utente corrente, controllando preventivamente
   * che ci sia una sessione utente attiva.
   */
  get(): Session {
    if (this.isActive()) {
      return {
        accessToken: this.tokenService.getAccessToken(),
        userData: this.userDataService.get()
      }
    } else {
      this.delete()
      this.router.navigate([RouterParams.PATH_LOGIN])
      return null
    }
  }

  /**
   * Pulisce la sessione utente, effettuando le seguenti azioni:
   * - Rimuove il token autorizzativo dal session storage
   * - Rimuove i dati utente dal session storage
   */
  delete() {
    this.tokenService.deleteAccessToken()
    this.userDataService.delete()
  }

  /**
   * Verifica se e' presente un utente in sessione,
   * effettuando i seguenti controlli:
   * - E' presente un token autorizzativo
   * - Sono presenti i dati utente
   * - Il token autorizzativo non e' scaduto
   */
  isActive(): boolean {
    if (this.tokenService.getAccessToken() && this.userDataService.get()) {
      const [issuedAt, expiresIn] = this.tokenService.getTokenExpiration()
      return this.isValidToken(issuedAt, expiresIn)
    }
    return false
  }

  /*
  TODO: implementazione lato client di gestione scadenza token
  Per ora si torna il token sempre valido e si delega all'AuthInterceptor
  di gestire l'errore 401 ritornato dal server
  */
  private isValidToken(issuedAt: number, expiresIn: number): boolean {
    return true
  }

  saveDashboardFromPrefernce(from: Date) {
    sessionStorage.setItem(USER_DASH_RANGE_PREF_START, from.toUTCString())
  }

  saveDashboardToPrefernce(to: Date) {
    sessionStorage.setItem(USER_DASH_RANGE_PREF_END, to.toUTCString())
  }
}
