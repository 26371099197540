import { Injectable } from '@angular/core';

/**
 * Servizio per attivazione e disattivazione degli spinner.
 */
@Injectable()
export class SpinnerService {

  constructor() { }

  load(){
    document.getElementById("nb-global-spinner").style.backgroundColor = "transparent"
    document.getElementById("nb-global-spinner").style.display = "inline"
  }

  stop(){
    document.getElementById("nb-global-spinner").style.display = "none"
  }

}
