<h1 id="title" class="title">Login</h1>

<form (ngSubmit)="login()" [formGroup]="loginForm" aria-labelledby="title">
    <div class="form-control-group">
        <label class="label">Username:</label>
        <input type="text" nbInput fullWidth placeholder="Username" formControlName="username" fieldSize="large"
            [status]="loginForm.get('username').invalid ? 'danger' : 'success'" autofocus>
    </div>
    <div class="form-control-group">
        <span class="label-with-link">
            <label class="label" for="input-password">Password:</label>
        </span>
        <input type="password" nbInput fullWidth placeholder="Password" formControlName="password" fieldSize="large"
            [status]="loginForm.get('password').invalid ? 'danger' : 'success'" autofocus>
        <span class="label-with-link" style="float: right;">
            <a class="forgot-password caption-2"
                routerLink="/auth/request-password">{{ 'message.passwordForgot' | translate}}</a>
        </span>
    </div>
    <button nbButton fullWidth status="primary" size="large" [class.btn-pulse]="submitted"
        [disabled]="submitted || !loginForm.valid">
        Login
    </button>
    <div class="row" style="float: right;">
        <span style="margin-right: 15px;"><small><b>v2.1.1</b></small></span>
    </div>
</form>