import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import it from '@angular/common/locales/it';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import {
  NbButtonModule,
  NbCardModule,
  NbChatModule,
  NbDatepickerModule, NbDialogModule,
  NbInputModule,
  NbMenuModule,
  NbSidebarModule,
  NbTimePickerComponent,
  NbToastrModule,
  NbToastrService,
  NbWindowModule, NB_TIME_PICKER_CONFIG
} from '@nebular/theme';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module'; // RECOMMENDED
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AbsLoginComponent } from './component/abs-login/abs-login.component';
import { AbsLogoutComponent } from './component/abs-logout/abs-logout.component';
import { AbsRequestPasswordComponent } from './component/abs-request-password/abs-request-password.component';
import { AbsResetPasswordComponent } from './component/abs-reset-password/abs-reset-password.component';
import { ApiService } from './service/api.service';
import { AuthInterceptor } from './service/auth/auth-interceptor';
import { AuthService } from './service/auth/auth.service';
import { SessionService } from './service/auth/session.service';
import { TokenService } from './service/auth/token.service';
import { UserDataService } from './service/auth/user-data.service';
import { ChartService } from './service/chart.services';
import { DashboardService } from './service/dashboard.services';
import { DataService } from './service/data.service';
import { EnvServiceProvider } from './shared/env.service.provider';
import { SharedModule } from './shared/shared.module';

registerLocaleData(it);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function localFunction() {
  if (localStorage.getItem('ADV_lang') == "it")
    return "it-IT"
  if (localStorage.getItem('ADV_lang') == "en")
    return "en-EN"
  else
    return "it-IT"
}

@NgModule({
  declarations: [
    AppComponent,
    AbsLoginComponent,
    AbsLogoutComponent,
    AbsRequestPasswordComponent,
    AbsResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    NbDatepickerModule.forRoot(),
    NbDateFnsDateModule.forRoot({ format: 'dd/MM/yyyy' }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }
    ),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
    }),
    NbInputModule,
    NbButtonModule,
    NbCardModule,
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    ModalModule.forRoot()
  ],
  providers: [
    {
      provide: APP_BASE_HREF, useValue: '/'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    Title,
    EnvServiceProvider,
    ApiService,
    AuthService,
    TokenService,
    UserDataService,
    SessionService,
    ChartService,
    DashboardService,
    DataService,
    NbToastrService,
    {
      provide: LOCALE_ID,
      deps: [],
      useFactory: localFunction
    },
    NbTimePickerComponent, { provide: NB_TIME_PICKER_CONFIG, useValue: {} }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
