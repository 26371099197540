/*
 * Copyright: DAASI International GmbH 2017-2020. All rights reserved.
 * This is Open Source Software
 * License: Apache 2.0 (https://www.apache.org/licenses/LICENSE-2.0)
 * Author: Benedikt Schnabel, DAASI International GmbH, www.daasi.de
 * For questions please mail to info@daasi.de
 */

import { Injectable } from '@angular/core';
import { Table, TableHeaderCheckbox } from 'primeng/table';

@Injectable()
/**
 * Fix issues with pagination and selection. see issues
 * - https://stackoverflow.com/questions/58692256/primeng-p-table-header-select-all-persistence-with-lazy-loading-and-pagination
 * - https://github.com/primefaces/primeng/issues/1206
 */
export class PrimeNGModificationService {

  /**
   * 1. Fix the displaying of the table header checkbox. it should be checked when all visible rows are checked
   * 2. only select / unselect all entries on current when clicking page even though lazy loading is switched off
   * @param lazyload is lazyloading used in the table?
   */
  overridePrimeNGTableMethodsForCheckboxSelection(lazyload: boolean): void {

    /* 1. Fix the displaying of the table header checkbox. it should be checked when all visible rows are checked */

    // overriding https://github.com/primefaces/primeng/blob/9.1.3/src/app/components/table/table.ts#L3630
    TableHeaderCheckbox.prototype.updateCheckedState = function () {
      
      if ((!this.dt.value && !this.dt.filteredValue) || !this.dt._selection) {
        return false;
      }

      // visibleRows algorithm from template: https://github.com/primefaces/primeng/blob/9.1.3/src/app/components/table/table.ts#L2163
      const visibleRows = (this.dt.paginator && !this.dt.lazy) ?
                          ((this.dt.filteredValue || this.dt.value).slice(this.dt.first, this.dt.first + this.dt.rows)) :
                          this.dt.filteredValue || this.dt.value;
      const visibleRowIds = visibleRows.map(r => r[this.dt.dataKey]);
      const selectedRowIdsSet =  new Set(this.dt._selection.map(r => r[this.dt.dataKey]));

      // Check if visibleRowIds ⊆ selectedRowIds
      // console.log('visibleRows', visibleRows);
      // console.log('visibleRowsIds', visibleRowIds);
      // console.log('slectedRowsIdsSet', selectedRowIdsSet);

      return visibleRowIds.filter(id => !selectedRowIdsSet.has(id)).length === 0;
    };

    /* 2. only select / unselect all entries on current when clicking page even though lazy loading is switched off */

    if (!lazyload) {
      // overriding https://github.com/primefaces/primeng/blob/9.1.3/src/app/components/table/table.ts#L1209
      Table.prototype.toggleRowsWithCheckbox = function (event, check) {
        const visibleRows = ((this.filteredValue || this.value).slice(this.first, this.first + this.rows));
        const visibleRowsSet = new Set(visibleRows);
        const selectionSet = new Set (this._selection);

        if (!check) {
          // Difference (selection - visibleRows)
          this._selection = this._selection.filter(r => !visibleRowsSet.has(r));
        } else {
          // Union (selection - visibleRows)
          const unionSet = new Set([...selectionSet, ...visibleRowsSet]);
          this.selection = [...unionSet];
        }

        this.preventSelectionSetterPropagation = true;
        this.updateSelectionKeys();
        this.selectionChange.emit(this._selection);
        this.tableService.onSelectionChange();
        this.onHeaderCheckboxToggle.emit({originalEvent: event, checked: check});

        if (this.isStateful()) {
            this.saveState();
        }

      };
    }

  }

}
